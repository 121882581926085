
    import Vue from 'vue';

    import Field from '@/components/Field.vue';
    import DateTimePicker from '@/components/DateTimePicker.vue';
    import DatePicker from '@/components/DatePicker.vue';
    import SelectBoolean from '@/components/SelectBoolean.vue';

    export default Vue.extend({
        name: 'FieldsTree',
        props: {
            variables: {
                required: true
            },
            variablesFields: {
                required: true
            },
            variablesErrors: {
                required: true
            },
            name: {
                type: String,
                default: ''
            }
        },
        methods: {
            getIndex(index: any) {
                return this.name ? this.name + '.' + index : index;
            }
        },
        components: {
            Field,
            DateTimePicker,
            DatePicker,
            SelectBoolean
        }
    });
