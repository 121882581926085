
    import Vue from 'vue';

    import RCP from '@/api/RCP.ts';

    import Field from '@/components/Field.vue';
    import SelectField from '@/components/SelectField.vue';
    import Modal from '@/components/Modal.vue';
    import FieldsTree from '@/components/generatefile/FieldsTree.vue';

    import Validator from '@/helpers/validator';

    import { ErrorResponse } from '@/types/Errors';

    import createHtml from '@/helpers/createHtml';

    export default Vue.extend({
        name: 'generateFile',
        props: {
            versionId: {
                type: Number,
                required: true
            },
            generator: {
                type: String,
                required: true
            },
            text: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                localVariables: {},
                version: {} as any,
                variablesRules: {},
                variablesErrors: {},
                variablesFields: {} as any,
                formRequest: false,
                request: false,
                showFields: false,
                options: {
                    formats: {}
                },
                properties: {} as any,
                format: '',
                loading: true
            };
        },
        created() {
            this.getVariables();
        },
        computed: {
            template(): any {
                return this.$store.state.template.info;
            }
        },
        watch: {
            formRequest(val) {
                if (val) {
                    this.$watch('variablesFields', () => {
                        this.validate(false);
                    }, {
                        deep: true
                    });
                }
            },
            localVariables(value) {
                const that = this;

                function create(variables: any, name: string = '') {
                    let keys: any[] = [];

                    if (variables) {
                        keys = Object.keys(variables);
                    }

                    if (keys.length > 0) {
                        keys.forEach((variable: string) => {
                            const newVar = name ? name  + '.' + variable : variable;
                            const type = variables[variable].type;

                            if (['object', '[]object'].indexOf(type) === -1) {
                                let val: any = variables[variable].example_value;

                                if (val === null) {
                                    val = '';
                                } else if (type === 'money' && val) {
                                    val = variables[variable].example_value / 100;
                                }

                                if (type !== 'bool') {
                                     val = val.toString() || '';
                                }

                                that.$set(that.variablesFields, newVar, val);
                                that.$set(that.variablesRules, newVar, '');
                                that.$set(that.variablesErrors, newVar, '');

                                // @ts-ignore
                                if (variables[variable].required) {
                                    if (variables[variable].type !== 'bool') {
                                        // @ts-ignore
                                        that.variablesRules[newVar] = 'required';
                                    } else {
                                        // @ts-ignore
                                        that.variablesRules[newVar] = 'boolRequired';
                                    }
                                }
                            } else {
                                if (variables[variable].type === '[]object') {
                                    that.$set(that.variablesFields, newVar,
                                        variables[variable].example_value);
                                    that.$set(that.variablesRules, newVar, '');
                                    that.$set(that.variablesErrors, newVar, '');

                                    // @ts-ignore
                                    if (variables[variable].required) {
                                        // @ts-ignore
                                        that.variablesRules[newVar] = 'required';
                                    }
                                }

                                create(variables[variable].properties, newVar);
                            }
                        });
                    }
                }

                create(value);
            },
            version() {
                this.generateFormats();
            }
        },
        methods: {
            getVariables() {
                this.loading = true;

                RCP({
                    method: 'App.Template.Version.get',
                    params: {
                        pk: this.versionId,
                        select: ['variables', 'page_property']
                    },
                    id: 'getVariablesVersion'
                }).then((result: any) => {
                    if (result.item) {
                       this.localVariables = result.item.variables;
                       this.properties = result.item.page_property;
                       this.version = result.item;
                    }

                    this.loading = false;
                }).catch((error: ErrorResponse) => {
                    if (error.show) {
                        alert('Ошибка : ' + error.message);
                    }

                    this.loading = false;
                });
            },
            generateFormats() {
                this.format = '';

                if (this.version.generator_file_out_format) {
                    this.version.generator_file_out_format.forEach((name: string) => {
                        if (!this.format) {
                            this.format = name;
                        }
                        // @ts-ignore
                        this.options.formats[name] = name;
                    });
                }
            },
            validate(submit = true) {
                if (!this.request) {
                    const validate = new Validator(this.variablesFields, this.variablesRules).make();
                    this.variablesErrors = validate.errors;

                    if (validate.isValid && submit) {
                        this.generateFile();
                    } else {
                        this.formRequest = true;
                        this.showFields = true;
                    }
                }
            },
            generateFile() {
                const that = this;

                createHtml(this.text, this.properties.format, this.properties.orientation).then((text: any) => {
                    function create(variablesObj: any, name: any = ''): any {
                        let keys: any[] = [];

                        const obj: any = {};

                        if (variablesObj) {
                            keys = Object.keys(variablesObj);
                        }

                        if (keys.length > 0) {
                            keys.forEach((variable: string) => {
                                const type = variablesObj[variable].type;
                                const newVar = name ? name  + '.' + variable : variable;

                                if (['object', '[]object'].indexOf(type) === -1) {

                                    if ([null, undefined, ''].indexOf(that.variablesFields[newVar]) === -1) {
                                        obj[variable] = that.variablesFields[newVar];

                                        if (type === 'money') {
                                            obj[variable] *= 100;
                                        }

                                        if (['float', 'int'].indexOf(type) !== -1) {
                                            obj[variable] *= 1;
                                        }

                                        if (type === 'datetime') {
                                            obj[variable] = obj[variable] ? new Date(obj[variable]).toISOString() : '';
                                        }
                                    }

                                    if (type.slice(0, 2) === '[]') {
                                        if (that.variablesFields[newVar]) {
                                            try {
                                                obj[variable] = JSON.parse(that.variablesFields[newVar]);
                                            } catch (e) {
                                                console.log(`Для переменной ${newVar} типа ${type} неверно задано значение. Заменено на пустой массив.`);
                                                obj[variable] = [];
                                            }
                                        } else {
                                            obj[variable] = [];
                                        }
                                    }

                                } else if (variablesObj[variable].properties) {
                                    obj[variable] = create(variablesObj[variable].properties, newVar);

                                    if (type === '[]object') {
                                        obj[variable] = [obj[variable]];
                                    }
                                }
                            });
                        }

                        return obj;
                    }

                    const variablesJSON = create(this.localVariables);

                    const form = document.createElement('form');
                    form.setAttribute('method', 'post');
                    form.setAttribute('action', '/preview/');
                    form.setAttribute('target', '_blank');

                    const templateVersion = document.createElement('input');
                    templateVersion.setAttribute('name', 'templateVersion');
                    templateVersion.setAttribute('type', 'hidden');

                    if (text !== '') {
                        if (text.substr(-13) === '<p>&nbsp;</p>') {
                            text = text.substr(0, text.length - 13);
                        }

                        templateVersion.setAttribute('value', JSON.stringify({
                            ...this.version,
                            text
                        }));
                    } else {
                        templateVersion.setAttribute('value', JSON.stringify(this.version));
                    }

                    const variables = document.createElement('input');
                    variables.setAttribute('name', 'variables');
                    variables.setAttribute('type', 'hidden');
                    variables.setAttribute('value', JSON.stringify(variablesJSON));

                    const format = document.createElement('input');
                    format.setAttribute('name', 'format');
                    format.setAttribute('type', 'hidden');
                    format.setAttribute('value', this.format);

                    form.appendChild(templateVersion);
                    form.appendChild(variables);
                    form.appendChild(format);

                    document.body.appendChild(form);

                    form.submit();

                    form.remove();

                });
            }
        },
        components: {
            Field,
            SelectField,
            Modal,
            FieldsTree
        }
    });
