const cm = 38;

const margin = 2;

const sizes = {
    A4: {
        width: (21 - margin * 2) * cm,
        height: (29.7 - margin * 2) * cm
    },
    A5: {
        width: (14.8 - margin * 2) * cm,
        height: (21 - margin * 2) * cm
    }
} as any;

export default (text: any, format: any = 'A4', orientation: any = 'book') => {
    return new Promise((resolve) => {
        function replaceElement(source: any, newType: any) {
            const frag = document.createDocumentFragment();
            while (source.firstChild) {
                frag.appendChild(source.firstChild);
            }
            const newElem = document.createElement(newType);
            const attributes = source.attributes;

            [...attributes].forEach((item: any) => {
                newElem.setAttribute(item.name, item.value);
            });

            newElem.appendChild(frag);
            source.parentNode.replaceChild(newElem, source);
        }

        const elem = document.createElement('div');
        const images = [] as any;

        text = '<style>* { font-size: 12pt; font-family: serif;} p {margin-bottom: 14.4pt; word-wrap: break-word;}</style>' + text;

        elem.innerHTML = text;

        elem.querySelectorAll('figure').forEach((item: any) => {
            item.style.margin = '0px';
        });

        elem.querySelectorAll('.table').forEach((item: any) => {
            replaceElement(item, 'div');
        });

        elem.querySelectorAll('p').forEach((item: any) => {
            item.classList.add('western');
        });

        elem.querySelectorAll('.image').forEach((item: any, index: number) => {
            images[index] = false;
            const pic = item.querySelector('img');
            let width = +item.style.width.slice(0, -2);
            pic.setAttribute( 'vspace', '20');

            const tmp = new Image();
            tmp.onload = () => {
                let flag = false;

                pic.align = 'center';

                if ((' ' + item.className + ' ').replace(/[\n\t]/g, ' ')
                    .indexOf(' image-style-align-right ') > -1) {
                    pic.setAttribute( 'hspace', '20');
                    pic.style.marginRight = '-20px';
                    pic.align = 'right';

                    const br = document.createElement('br');

                    item.appendChild(br);

                    const nextSibling = item.nextSibling;

                    if (nextSibling.tagName === 'P') {
                        const imgText = document.createElement('span');
                        imgText.setAttribute('lang', 'ru-RU');
                        imgText.innerHTML = nextSibling.innerHTML;

                        item.appendChild(imgText);

                        nextSibling.remove();
                    }

                    flag = true;
                } else if ((' ' + item.className + ' ').replace(/[\n\t]/g, ' ')
                    .indexOf(' image-style-align-left ') > -1) {
                    pic.setAttribute( 'hspace', '20');
                    pic.style.marginLeft = '-20px';
                    pic.align = 'left';

                    const br = document.createElement('br');

                    item.appendChild(br);

                    const nextSibling = item.nextSibling;

                    if (nextSibling.tagName === 'P') {
                        const imgText = document.createElement('span');
                        imgText.setAttribute('lang', 'ru-RU');
                        imgText.innerHTML = nextSibling.innerHTML;

                        item.appendChild(imgText);

                        nextSibling.remove();
                    }

                    flag = true;
                }

                let height = 0;

                if ((orientation === 'book' && tmp.width > sizes[format].width) ||
                    (orientation !== 'book' && tmp.width > sizes[format].height)) {
                    if (width) {
                        const kf = width / tmp.width;
                        height = tmp.height * kf;
                    } else {
                        width = sizes[format].width;

                        if (orientation !== 'book') {
                            width = sizes[format].height;
                        }

                        if (flag) {
                            width /= 2;
                        }

                        const kf = width / tmp.width;
                        height = tmp.height * kf;
                    }
                } else {
                    if (width) {
                        const kf = width / tmp.width;
                        height = tmp.height * kf;
                    } else {
                        width = tmp.width;
                        height = tmp.height;
                    }
                }

                pic.height = `${height}`;
                pic.width = `${width}`;
                pic.border = '0';

                images[index] = true;

                // pic.removeAttribute('style');
                item.removeAttribute('style');
                item.removeAttribute('class');

                item.classList.add('western');

                if (!flag) {
                    item.setAttribute('align', 'center');
                }

                replaceElement(item, 'p');
            };
            tmp.src = pic.src;
        });

        const checkImages = () => {
            const res = images.indexOf(false);

            if (res !== -1) {
                setTimeout(() => {
                    checkImages();
                }, 100);
            } else {
                resolve(elem.innerHTML);
            }
        };

        if (images.length === 0) {
            resolve(elem.innerHTML);
        } else {
            checkImages();
        }
    });
};
